<template>
    <div class="view pa24">
        <div class="d-flex justify-content-between mr50 statistics">
            <div>
                <div class="mb5">管理面积</div>
                <div><span class="val">{{census.totalArea}}</span>㎡</div>
                <div>总房源{{census.totalNum}}</div>
            </div>
            <div>
                <div class="mb5">空置面积</div>
                <div><span class="val">{{census.notUseArea}}</span>㎡</div>
                <div>房源{{census.notUseNum}} ({{census.vacancyRate}}%)</div>
            </div>
            <div>
                <div class="mb5">已租面积</div>
                <div><span class="val">{{census.useArea}}</span>㎡</div>
                <div>房源{{census.useNum}} ({{census.occupancyRate}}%)</div>
            </div>
            <div>
                <div class="mb5">出租率</div>
                <div><span class="val">{{census.occupancyRate}}</span>%</div>
                <div>总房源</div>
            </div>
        </div>
        <div class="tipsView d-flex mb20 mt20">
            <div class="tipsItem d-flex mr20" v-for="(row, index) in tipsData" :key="index">
                <div class="tipsColor" :style="'background:' + row.tColor"></div>
                <div class="tipsName">{{ row.tName }}</div>
            </div>
        </div>
        <div class="spaceView">
            <div class="buildView">
                <div class="buildItem" :class="{ 'on': buildId == row.buildId }" v-for="(row, index) in buildData"
                    :key="index" @click="selectBuild(row)">{{ row.buildName }}</div>
            </div>
            <div class="unitView" v-loading="unitLoading">
                <div class="unitItem" :class="{ 'on': unitId == row.id }" v-for="(row, index) in unitData" :key="index"
                    @click="selectUnit(row)">{{ row.name }}</div>
            </div>
            <div class="spaceContent" v-if="floorHouse[unitId]" v-loading="houseLoading">
                <div class="floorHouseView" v-for="(row, index) in floorHouse[unitId]" :key="index">
                    <div class="floor flex-c-c">
                        {{ index }}楼
                    </div>
                    <div class="houseView">
                        <div class="houseItem pall10 mr10" v-for="(house, key) in row" :key="key"
                            :style="'width:calc(' + house.w + ' - 10px);background:' + hColor[house.status]"
                            :class="{ 'no': house.status == 7 || house.status == 2 || house.status == 1 }">
                            <div class="text-overflow" v-if="house.companyName">{{house.companyName}}</div>
                            <div class="text-overflow">{{house.houseName}} ({{house.houseArea}}㎡)</div>
                            <div class="time mt5 text-overflow" v-if="house.endTime && house.status!==7">
                                {{house.endTime}}到期</div>
                            <div class="time" v-if="house.status===7">已到期</div>
                            <div class="time" v-if="house.status===1">空置中</div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="spaceContent flex-c-c" v-else-if="!floorHouse[unitId] && !houseLoading">无房屋数据</div>
        </div>
    </div>
</template>

<script>
import { getBuildTotalData, getBuildHouseList } from "@/api/propertyServices";
export default {
    data() {
        return {
            buildId: "",
            buildData: [],//栋数据
            unitId: "",
            unitData: [],//单元数据
            tipsData: [
                { tColor: "#a9dcfd", tName: "空置中" },
                // { tColor: "#ffffff", tName: "招商中" },
                // { tColor: "#ce9ced", tName: "已锁定" },
                { tColor: "#c6e0a7", tName: "已出租" },
                { tColor: "#fecb85", tName: "90日到期" },
                { tColor: "#fdac93", tName: "30日到期" },
                { tColor: "#ff7875", tName: "已过期" }
            ],
            hColor: {
                1: "#a9dcfd",
                2: "#ffffff",
                3: "#ce9ced",
                4: "#c6e0a7",
                5: "#fecb85",
                6: "#fdac93",
                7: "#ff7875"
            },
            floorHouse: {},//楼层/房屋
            parkId: 0,
            unitLoading: false,
            houseLoading: false,
            census: {
                "useArea": 0,// 已租面积,
                "notUseArea": 0, //空置面积,
                "totalNum": 0, //总房屋数,
                "useNum": 0, //已租房屋数,
                "notUseNum": 0, //空置房屋数,
                "totalArea": 0,// 总面积
                "occupancyRate": 0,
                "vacancyRate":0
            },
        }
    },
    created() {
        let checkedPark = sessionStorage.getItem('checkedPark');
        this.parkId = JSON.parse(checkedPark).parkId;
        this.getBuildHouseList();
        this.getBuildTotalData();
    },
    computed: {
        watchCheckedPark() {
            return this.$store.state.checkedPark
        }
    },
    watch: {
        watchCheckedPark(row) {
            this.parkId = row.parkId;
            this.getBuildHouseList();
            this.getBuildTotalData();
        }
    },
    methods: {
        getBuildTotalData() {
            getBuildTotalData({ parkId: this.parkId }).then(res => {
                let data = res.data;
                this.census = {
                    "useArea": data.useArea, //已租面积,
                    "notUseArea": data.notUseArea, //空置面积,
                    "totalNum": data.totalNum, //总房屋数,
                    "useNum": data.useNum, //已租房屋数,
                    "notUseNum": data.notUseNum, //空置房屋数,
                    "totalArea": data.totalArea, //总面积
                    "occupancyRate": Math.round((data.useNum / data.totalNum) * 100),
                    "vacancyRate":Math.round((data.notUseNum / data.totalNum) * 100)
                };
            })
        },
        selectBuild(row) {
            this.buildId = row.buildId;
            this.getUnitHouse(this.buildId);
        },
        selectUnit(row) {
            this.unitId = row.id;
            this.getHouse(this.unitId);
        },
        /**@method 获取房屋 */
        getHouse(number) {
            this.houseLoading = true;
            let params = { parkId: this.parkId, buildId: this.buildId, number };
            getBuildHouseList(params).then(res => {
                setTimeout(() => { this.houseLoading = false; }, 1000)
                let data = res.data;
                if (res.data) {
                    if (data.houseMapList) {
                        let floorHouse = {};
                        for (let floor of data.houseMapList) {
                            for (let house of floor.houseList) {
                                house.w = (house.houseArea / floor.totalAreaSize) * 100 + "%";
                                if (house.familyId) {
                                    house.status = 4;
                                    if (house.endTime) {
                                        house.endTime = house.endTime.slice(0, 10);
                                        let endTime = new Date(house.endTime).getTime();
                                        let time = new Date().getTime();
                                        let day30 = 1000 * 60 * 60 * 24 * 30
                                        if ((endTime - (day30 * 3)) < time) {
                                            house.status = 5;
                                        }
                                        if ((endTime - day30) < time) {
                                            house.status = 6;
                                        }
                                        if (endTime < time) {
                                            house.status = 7;
                                        }
                                    }
                                } else {
                                    house.status = 1;
                                }
                            }
                            if (floorHouse[floor.number]) {
                                floorHouse[floor.number][floor.layers] = floor.houseList;
                            } else {
                                floorHouse[floor.number] = {};
                                floorHouse[floor.number][floor.layers] = floor.houseList;
                            }
                        }
                        this.floorHouse = floorHouse;
                    }
                }
            })
        },
        /**@method 获取单元、房屋 */
        getUnitHouse(buildId,) {
            this.houseLoading = true;
            this.unitLoading = true;
            let params = { parkId: this.parkId, buildId };
            getBuildHouseList(params).then(res => {
                setTimeout(() => { this.unitLoading = false; this.houseLoading = false; }, 1000)
                let data = res.data;
                if (res.data) {
                    if (data.numberList) {
                        let unitData = [];
                        for (let row of data.numberList.sort()) {
                            unitData.push({ id: row, name: row + "单元" })
                        }
                        this.unitData = unitData;
                        if (this.unitData.length > 0) {
                            this.unitId = this.unitData[0].id
                        }
                    }
                    if (data.houseMapList) {
                        let floorHouse = {};
                        for (let floor of data.houseMapList) {
                            for (let house of floor.houseList) {
                                house.w = (house.houseArea / floor.totalAreaSize) * 100 + "%";
                                if (house.familyId) {
                                    house.status = 4;
                                    if (house.endTime) {
                                        house.endTime = house.endTime.slice(0, 10);
                                        let endTime = new Date(house.endTime).getTime();
                                        let time = new Date().getTime();
                                        let day30 = 1000 * 60 * 60 * 24 * 30
                                        if ((endTime - (day30 * 3)) < time) {
                                            house.status = 5;
                                        }
                                        if ((endTime - day30) < time) {
                                            house.status = 6;
                                        }
                                        if (endTime < time) {
                                            house.status = 7;
                                        }
                                    }
                                } else {
                                    house.status = 1;
                                }
                            }
                            if (floorHouse[floor.number]) {
                                floorHouse[floor.number][floor.layers] = floor.houseList;
                            } else {
                                floorHouse[floor.number] = {};
                                floorHouse[floor.number][floor.layers] = floor.houseList;
                            }
                        }
                        this.floorHouse = floorHouse;
                    }
                }
            })
        },
        /**@method 获取楼宇、单元 */
        getBuildHouseList() {
            this.houseLoading = true;
            this.unitLoading = true;
            getBuildHouseList({ parkId: this.parkId }).then(res => {
                setTimeout(() => { this.unitLoading = false; this.houseLoading = false; }, 1000)
                if (res.data) {
                    let data = res.data;
                    this.buildData = data.buildList;
                    if (this.buildData.length > 0) {
                        this.buildId = this.buildData[0].buildId
                    }
                    if (data.numberList) {
                        let unitData = [];
                        for (let row of data.numberList.sort()) {
                            unitData.push({ id: row, name: row + "单元" })
                        }
                        this.unitData = unitData;
                        if (this.unitData.length > 0) {
                            this.unitId = this.unitData[0].id
                        }
                    }
                    if (data.houseMapList) {
                        let floorHouse = {};
                        for (let floor of data.houseMapList) {
                            for (let house of floor.houseList) {
                                house.w = (house.houseArea / floor.totalAreaSize) * 100 + "%";
                                if (house.familyId) {
                                    house.status = 4;
                                    if (house.endTime) {
                                        house.endTime = house.endTime.slice(0, 10);
                                        let endTime = new Date(house.endTime).getTime();
                                        let time = new Date().getTime();
                                        let day30 = 1000 * 60 * 60 * 24 * 30
                                        if ((endTime - (day30 * 3)) < time) {
                                            house.status = 5;
                                        }
                                        if ((endTime - day30) < time) {
                                            house.status = 6;
                                        }
                                        if (endTime < time) {
                                            house.status = 7;
                                        }
                                    }
                                } else {
                                    house.status = 1;
                                }
                            }
                            if (floorHouse[floor.number]) {
                                floorHouse[floor.number][floor.layers] = floor.houseList;
                            } else {
                                floorHouse[floor.number] = {};
                                floorHouse[floor.number][floor.layers] = floor.houseList;
                            }
                        }
                        this.floorHouse = floorHouse;
                    }
                }
            })
        }
    }
}
</script>

<style scoped lang="scss">
.statistics {
    font-size: 12px;

    .val {
        font-size: 20px;
        color: #000;
    }
}

.tipsView {
    .tipsColor {
        height: 20px;
        width: 20px;
        margin-right: 5px;
        border: 1px dashed #999;
    }

    .tipsName {
        line-height: 20px;
    }
}

.spaceView {
    height: 600px;
    display: flex;

    .buildView {
        border-left: 1px solid #eee;
        border-right: 1px solid #eee;
        width: 100px;
        height: 100%;
        text-align: center;

        .buildItem {
            line-height: 30px;
            cursor: pointer;

        }

        .buildItem.on {
            background: #f1fbfb;
            color: #51cbcd;
            border-right: 2px solid #51cbcd;
            border-left: 2px solid #51cbcd;
        }
    }

    .unitView {
        border-right: 1px solid #eee;
        width: 100px;
        height: 100%;
        text-align: center;

        .unitItem {
            line-height: 30px;
            cursor: pointer;

        }

        .unitItem.on {
            background: #f1fbfb;
            color: #51cbcd;
            border-right: 2px solid #51cbcd;
            border-left: 2px solid #51cbcd;
        }
    }

    .spaceContent {
        width: calc(100% - 200px);
        overflow-y: auto;

        .floorHouseView {
            display: flex;

            .floor {
                height: 80px;
                width: 80px;
                background: #f5f5f5;
                margin: 0 10px 10px 10px;
                border-radius: 5px;
            }

            .houseView {
                width: calc(100% - 100px);
                display: flex;
                color: #000;

                .houseItem {
                    height: 80px;
                    border-radius: 5px;
                    border: 2px solid rgba(238, 238, 238, 0.3);
                    overflow: hidden;
                }

                .time {
                    font-size: 12px;
                    color: #666;
                }

                .houseItem.no {
                    border: 2px dashed rgba(0, 0, 0, 0.3) !important;
                }
            }
        }
    }
}
</style>